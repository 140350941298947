// This file is used to update the global data on the site.
// To reduce the amount of API calls, we should bundle the data together, and then update the stores.

// Stores
import { useGlobalStore } from '@/stores/global';
import { useHeaderStore } from '@/stores/header';
import { useFooterStore } from '@/stores/footer';

// Composables
import { useNuxtAPI } from '~/composables/data/useAPI';
import { useHoursStore } from '~/stores/hours';

export const updateGlobals = async () => {
 // Make the API calls
 // const global = await useNuxtAPI('global-data');
 const { data: global } = await useFetch('/api/global-data');

 const { data } = unref(global);

 // Update the stores
 if (data) {
  useGlobalStore().init(data);
  useHeaderStore().init(data.header);
  useFooterStore().init(data.footer);

  const hours = {
   base: data.centerHours,
   special: data.specialHours,
   message: data.hoursMessage,
  };
  useHoursStore().init(hours);
 }

 return data;
};
