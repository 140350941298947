import { scrollTo } from '@/composables/helpers/client-only/scroll';

export const initializeDocumentEvents = () => {
	const nuxtApp = useNuxtApp();
	const { $bus } = nuxtApp;

	// Lifecycle Logic
	onMounted(() => {
		$bus.on('scroll-lock', () => {
			const offset = window.scrollY;
			window.lastOffset = offset;

			document.body.style.top = `-${offset}px`;
			document.body.style.overflow = 'hidden';
			document.body.style.width = '100%';
			document.body.style.position = 'fixed';
			document.body.style['padding-right'] = '15px';
		});

		$bus.on('scroll-unlock', () => {
			const { hash } = useRoute();
			let topX = '';
			if (hash) {
				topX = document?.querySelector(hash)?.getBoundingClientRect()?.top || '';
			}

			if (!topX) {
				const cashedOffset = parseInt(document.body.style.top.replace('px', '')) * -1;
				document.body.style.top = null;
				document.body.style.overflow = null;
				document.body.style.width = null;
				document.body.style.position = null;
				document.body.style['padding-right'] = null;
				setTimeout(() => {
					window.scrollTo({
						top: cashedOffset,
						left: 0,
						behavior: 'instant',
					});
				}, 16);
			}
		});

		scrollTo();
	});

	onBeforeUnmount(() => {
		$bus.off('scroll-lock');
		$bus.off('scroll-unlock');
	});

	nuxtApp.hook('page:finish', () => {
		scrollTo();
	});
};
