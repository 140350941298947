export const handleBuilderClasses = (count = 0) => {
	const builderBlocks = document.querySelectorAll('.builder-block');

	// Move bg-* classes to parent
	builderBlocks.forEach((block) => {
		const child = block.firstElementChild || false;
		if (!child) return;

		const classes = child.classList;

		// class is a reserved keyword in JavaScript
		classes.forEach((classs) => {
			if (classs.includes('bg-')) {
				block.classList.add(classs);
			}
		});

		// Check for symbols and add the bg class to the symbol element
		const blockClasses = Array.from(block.classList);
		if (blockClasses.includes('builder-symbol')) {
			const nestedBlock = block.querySelector('div .builder-blocks .builder-block');
			if (nestedBlock) {
				const nestedBlockClasses = Array.from(nestedBlock.classList);
				nestedBlockClasses.forEach((blockClass) => {
					if (blockClass.includes('bg-')) {
						block.classList.add(blockClass);
					}
				});
			}
		}
	});

	// Re-loop to make sure all blocks are accounted for
	if (count <= 10) {
		setTimeout(() => {
			handleBuilderClasses(count + 1);
		}, 100);
	}
};
