// Composables
import { initializeDocumentEvents } from '@/composables/helpers/layouts/document';
import { initializeWindowEvents } from '@/composables/helpers/layouts/window';
import { updateGlobals } from '@/composables/data/updateGlobals';

const initializeLayout = async () => {
 console.log('initializeLayout');

 initializeWindowEvents();
 initializeDocumentEvents();

 await updateGlobals();
};

export { initializeLayout };
