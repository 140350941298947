export const handleServerAOS = (count = 0) => {
	const elements = document.querySelectorAll('.server-v-aos');

	// There's probaly a better way to do this, but I'm not sure what it is
	if (count < 10) {
		setTimeout(() => {
			handleServerAOS(count + 1);
		}, 100);
	}

	const options = {
		threshold: 0.5,
	};
	const animateOnScrollObserver = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.remove('v-aos-before-enter');
				entry.target.classList.add('v-aos-entered');
			}
		});
	}, options);

	elements.forEach((element) => {
		element.classList.add('v-aos');
		element.classList.add('v-aos-before-enter');
		animateOnScrollObserver.observe(element);
	});

	return elements;
};
