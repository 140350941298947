// Stores
import { useWindowStore } from '@/stores/window';
import { handleServerAOS } from '@/composables/server/aos';
import { handleBuilderClasses } from '@/composables/server/builder-classes';
// Composables

// Global Variables (To access elsewhere)
let GLOBAL_WINDOW_STORE = ref(null);
// let GLOBAL_USER_STORE = ref(null);

export const initializeWindowEvents = () => {
 if (process.server) return;

 // Instantiate store
 GLOBAL_WINDOW_STORE = useWindowStore();
 // GLOBAL_USER_STORE = useUserStore();

 // Lifecycle Logic
 onMounted(() => {
  handleWindowResize();

  // GLOBAL_USER_STORE.initUser();

  window.addEventListener('resize', handleWindowResize);
  window.addEventListener('scroll', handleWindowScroll);

  handleWindowScroll();
 });

 // Handle Server AOS
 useNuxtApp().hook('page:loading:end', () => {
  handleServerAOS();
  handleBuilderClasses();
 });

 onBeforeUnmount(() => {
  window.removeEventListener('resize', handleWindowResize);
  window.removeEventListener('scroll', handleWindowScroll);
 });
};

const handleWindowScroll = () => {
 if (process.server) return;
 if (!GLOBAL_WINDOW_STORE) return;

 GLOBAL_WINDOW_STORE.updateScrollY(window.scrollY);
};

const handleWindowResize = () => {
 if (process.server) return;
 if (!GLOBAL_WINDOW_STORE) return;

 // Update window store Height && Width
 GLOBAL_WINDOW_STORE.updateWidth(window.innerWidth);
 GLOBAL_WINDOW_STORE.updateHeight(window.innerHeight);

 // Keep --vh custom property up to date
 if (process.client) document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
 if (process.client) document.documentElement.style.setProperty('--vw', `${window.innerWidth / 100}px`);

 // Update window store deviceIs
 GLOBAL_WINDOW_STORE.updateDeviceIs();
};
